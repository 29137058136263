<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="pub_title_box flex">
      <pubSearch @search="search" place_holder="请输入户外名称"/>
      <pubStatus :status_list="status_list" @status_tab="status_tab"/>
    </div>
    <div class="table_box">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
                         fixed="left" header-align="center" prop="title" label="户外名称" width="200">
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" prop="address" label="广告位置" width="120">
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" prop="portal_title" label="广告类型" width="120">
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" prop="area_title" label="覆盖区域" width="120">
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" label="投放价" width="300">
          <template slot-scope="scope">
                        <span class="guanggao_price_span" v-for="(item, index) in scope.row.guanggao_price"
                              :key="index">{{
                            item ? item : '-'
                          }}元/{{ guanggao_price_list[index] }}</span>
          </template>
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" prop="design_rule" label="设计规格" width="120">
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" label="设计单位" width="120">
          <template slot-scope="scope">
            {{ design_unit_list[scope.row.design_unit] }}
          </template>
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" prop="design_price" label="设计价格" width="120">
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" label="状态" width="120">
          <template slot-scope="scope">
            <p :style="{ color: status_list[scope.row.examine_status + 1].color }">{{
                status_list[scope.row.examine_status
                + 1].title
              }}</p>
          </template>
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" prop="remark" label="下单备注" width="120">
          <template slot-scope="scope">
            <td_remark :text="scope.row.remark"/>
          </template>
        </el-table-column>

        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" label="退回原因" width="130">
          <template slot-scope="scope">
            <td_remark :text="scope.row.reason"/>
          </template>
        </el-table-column>
        <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                         header-align="center" align="center" fixed="right" label="操作" width="160">
          <template slot-scope="scope">
                        <span style="cursor: pointer; color: #108CDD;margin-right: 15px;"
                              @click.stop="upDateFn(scope.row)">编辑</span>
            <span style="cursor: pointer; color: #00B052;" v-if="scope.row.status == 1"
                  @click="UpClick(scope.row)">申请上架</span>

            <span style="cursor: pointer;color: #FF8400;"
                  v-if="scope.row.status == 0 && scope.row.examine_status == 1"
                  @click="reason(scope.row)">暂停</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
  </div>
</template>

<script>
import pubStatus from '@/components/pub_media_status'
import pubSearch from '@/components/pubSearch'
import {guanggao_price_list} from '@/util/guanggao_price_list'
import {design_unit_list} from '@/util/design_unit_list'

export default {
  components: {
    pubStatus,
    pubSearch,
  },
  mounted() {
    this.get_list()
  },
  data() {
    return {
      title: '',
      fullscreenLoading: false,
      tableData: [],
      guanggao_price_list: guanggao_price_list,
      design_unit_list: design_unit_list,
      status_list: [{
        id: '',
        title: '全部'
      }, {
        id: 0,
        title: '待审核',
        color: '#FF8400'
      }, {
        id: 1,
        title: '已审核',
        color: '#00B052'
      }, {
        id: 2,
        title: '被退回',
        color: '#00B052'
      },
        {
          id: 4,
          title: '暂停中',
          color: '#FF0000'
        }],

      total_page: 0, //分页总页数
      count: 0, //总条数
      page: 1,
    }
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      data.category_id = 1
      data.page = this.page
      data.limit = 20
      if (this.examine_status !== '') {
        data.examine_status = this.examine_status
      }
      if (this.title != '') {
        data.title = this.title
      }
      this.curlGet('/api/users/guanggao_info/list', data).then(res => {
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list
          this.tableData.forEach(item => {
            item.guanggao_price = JSON.parse(item.guanggao_price)
            console.log(item.guanggao_price);
          })
        }
        this.fullscreenLoading = false

      })
    },
    search(title) {
      this.title = title
      this.get_list()
    },
    status_tab(id) {
      this.examine_status = id
      this.pageChange(1)
    },
    // 暂停
    reason(item) {
      this.$confirm('是否暂停该广告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/guanggao_info/edit_status', {
          id: item.id,
          status: 1,
        }).then(res => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '暂停成功'
            });
            this.get_list()
          }
        })
      })

    },
    // 申请上架
    UpClick(item) {
      this.$confirm('是否申请上架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/guanggao_info/apply', {
          id: item.id,
        }).then(res => {
          console.log(res);
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '申请成功'
            });
            this.get_list()
          }
        })

      })

    },
    // 编辑
    upDateFn(item) {
      this.$router.push({
        path: '/placard_user/add_placard/add_outdoors',
        query: {
          // id: this.$store.state.AddRsId
          id: item.id
        }
      })
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/pubTable';
@import '@/scss/placard_media';
</style>